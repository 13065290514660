/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "primeicons/primeicons.css";

// #FF453A
// #4A4A4A

$primary-color: black;
$secondary-color: #4A4A4A;
$tertiary-color: #FF453A;

$font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

/* -------------------------------------------------------------------------- */
/*                              ANCHOR BACKGRONDS                             */
/* -------------------------------------------------------------------------- */

.bg-center {
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}

.bg-primary {
   background-color: $primary-color !important;
}

.bg-secondary {
   background-color: $secondary-color !important;
}

.bg-tertiary {
   background-color: $tertiary-color;
}

// #e0e0e0 grey lighten-2
.bg-grey-lighten-2 {
   background-color: #e0e0e0;
}

// #212121 grey darken-4
.bg-grey-darken-4 {
   background-color: #212121
}

.bg-white-semi-transparent {
   background-color: rgba(255, 255, 255, 0.6) !important;
}

.bg-white-semi-transparent-3 {
   background-color: rgba(255, 255, 255, 0.3) !important;
}

/* -------------------------------------------------------------------------- */
/*                             ANCHOR FONT WEIGHT                             */
/* -------------------------------------------------------------------------- */

@each $font-weight in $font-weights {
   .font-weight-#{$font-weight} {
      font-weight: $font-weight;
   }
}

/* -------------------------------------------------------------------------- */
/*                                 ANCHOR TEXT                                */
/* -------------------------------------------------------------------------- */

.text-terteary {
   color: $tertiary-color
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(111, 112, 111, 0.2);
  border-right: 1.1em solid rgba(111, 112, 111, 0.2);
  border-bottom: 1.1em solid rgba(111, 112, 111, 0.2);
  border-left: 1.1em solid #464646;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}